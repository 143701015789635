import { Component, OnInit } from '@angular/core';

import { Project } from '../project';


@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {
  raw_projects: Project[] = [
    {
      name: "4Gravity",
      info: "WEB GAME DESIGN & DEV",
      url: "https://chonseng.github.io/four-gravity/",
      img_src: "assets/img/4gravity.png",
    },
    {
      name: "Used Book Selling System",
      info: "WEB DESIGN & DEV",
      url: "http://home.puiching.edu.mo/~pcmssa/book/",
      img_src: "assets/img/book_selling.png",
    },
    {
      name: "LifeMaster",
      info: "ANDROID DESIGN & DEV",
      url: "http://lifemaster.chonseng.info/",
      img_src: "assets/img/lifemaster.png",
    },
    {
      name: "Parking Record",
      info: "WEB APP DESIGN & DEV",
      url: "http://chonseng.info/parkingrecords",
      img_src: "assets/img/parking_records.png",
    },
    {
      name: "生活專家",
      info: "iOS DESIGN & DEV",
      url: "http://lifeapp.chonseng.info",
      img_src: "assets/img/lifeapp.png",
    },
  ]

  projects: Project[][] = [];

  constructor() { }

  ngOnInit() {
    let projects_per_row = 2;
    for (let i = 0; i < this.raw_projects.length; i += projects_per_row) {
      let row = this.raw_projects.slice(i, i + projects_per_row);
      this.projects.push(row);
    }

  }

}
